#stepperGradient {
  width: 100%;
  height: 6px;
  border-radius: 8px;
  background-color: #9360a8;
  position: relative;
  overflow: hidden;
}

@keyframes stepperGradient {
  0% {
    transform: translateX(-50px);
    width: 20%;
    box-shadow: 0 2px 10px rgb(65, 211, 255);
  }
  90% {
    width: 50%;
  }
  100% {
    transform: translateX(150px);
    width: 100%;
  }
}
#stepperGradient::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 6px;
  background: linear-gradient(120deg, #9360a8, #46b7e9);
  border-radius: inherit;
  animation: stepperGradient infinite 2.6s linear;
  box-shadow: 5px 5px 15px rgb(78, 202, 255);
}
#stepperComplete {
  width: 100%;
  height: 6px;
  background-color: #9360a8;
}
#stepperInComplete {
  width: 100%;
  height: 6px;
  background-color: #C2C2C2;
  
}
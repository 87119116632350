@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .interactive-text-color {
    @apply hover:text-secondary-button-400 active:text-primary-button-400;
  }
  .interactive-button-color {
    @apply hover:border-secondary-button-400 hover:bg-secondary-button-400 active:text-primary-button-100 active:bg-primary-button-700 active:border active:border-primary-button-400;
  }
}

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap");
body {
  margin: 0;
  font-family: Nunito, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hovered-item {
  border: 3px solid #9360a8;
  border-width: 3px;
  border-style: solid;
  border-radius: 8px !important;
  z-index: 0;
  margin-top: -27px;
  border-radius: 8px;
  /* border: 3px solid linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%);  */
  /* background: linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%) border-box;  */
  /* -webkit-mask:  linear-gradient(transparent) padding-box, linear-gradient(transparent); */
  /* -webkit-mask-composite: xor;
          mask-composite: exclude; */
}

.animated-gradient {
  background: linear-gradient(120deg, #9360a8, #46b7e9);
  /* animation: gradientAnimation 2s linear infinite; */
}

@keyframes gradientAnimation {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: -100% 0%;
  }
}

@media (max-width: 768px) {
  .hovered-item {
    border: 3px solid #9360a8;
    border-width: 3px;
    border-style: solid;
    border-radius: 8px !important;
    margin-top: 0px;
    z-index: 0;
    border-radius: 8px;
  }
}

/* .hovered-item {
  position: relative;
}
.hovered-item::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px; 
  margin-top: -27px;
  border: 3px solid transparent; 
  background: linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%) border-box;
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
} */


  @keyframes smoothWidth {
    from {
      width: 1%;
    }
    to {
      width: 100%;
    }
  }
  
  .transition-all {
    animation: smoothWidth 3s ease-out;
  }
  